import { ApolloProvider as BaseApolloProvider } from "@apollo/client";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as apolloActions from "@/actions/apolloActions";
import * as errorPageActions from "@/actions/errorPageActions";
import * as userActions from "@/actions/userActions";
import { wsConnectionStatusEnum } from "@/enums/networkEnum";
import { errorPageContent } from "@/selectors/errorPageSelectors";
import * as userSelectors from "@/selectors/userSelectors";
import { initializeApollo } from "./apolloClient";
import { createSubscriptionClient } from "./subscriptionClient";

export class ApolloProvider extends Component {
  constructor() {
    super();
    this.state = {
      subscriptionClient: null,
      apolloClient: null,
    };
  }

  componentDidMount() {
    /* Also called on full reloads from pasting a url into the browser and going there */
    this.createClients();
    window.addEventListener("offline", this.offlineHandler);
    window.addEventListener("online", this.onlineHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.offlineHandler);
    window.removeEventListener("online", this.onlineHandler);
  }

  componentDidUpdate(prevProps) {
    const { token, initialState } = this.props;
    const { token: prevToken, initialState: prevInitialState } = prevProps;

    const shouldCreateClients =
      token !== prevToken || initialState !== prevInitialState;

    /*
      Create both a subscription client and an apollo client when
      "token" or "initialState" changes
    */
    if (shouldCreateClients) {
      this.createClients();
      return;
    }
  }

  onlineHandler = () => {
    // console.log("You are back online 🎉️");
    this.createClients();
  };

  offlineHandler = () => {
    // console.log("You are offline 😥️");
    this.state.subscriptionClient?.close();
  };

  createApolloClient = (subscriptionClient) => {
    const { token, initialState, onLogOut, onSetIsAuthClientReady } =
      this.props;

    const apolloClient = initializeApollo({
      token,
      initialState,
      subscriptionClient,
      onLogOut,
    });

    this.setState(
      {
        subscriptionClient,
        apolloClient,
      },
      () => {
        onSetIsAuthClientReady({ isAuthClientReady: Boolean(token) });
      },
    );
  };

  createClients = () => {
    const { subscriptionClient } = this.state;
    const { token, onSetConnectionStatus } = this.props;
    const {
      connectCallback,
      disconnectCallback,
      reconnectingCallback,
      reconnectedCallback,
    } = this;

    onSetConnectionStatus({
      wsConnectionStatus: wsConnectionStatusEnum.inProgress,
    });

    /* Close WS connection we have open if any */
    subscriptionClient?.close();

    const newSubscriptionClient = createSubscriptionClient({
      token,
      connectCallback,
      disconnectCallback,
      reconnectingCallback,
      reconnectedCallback,
    });

    this.createApolloClient(newSubscriptionClient);
  };

  connectCallback = () => {
    const {
      isForbiddenRouteErrorPage,
      onClearErrorPage,
      onSetConnectionStatus,
    } = this.props;

    onSetConnectionStatus({
      wsConnectionStatus: wsConnectionStatusEnum.connected,
    });

    /* Clear error page in case errors had occurred unless it is a forbidden route */
    if (!isForbiddenRouteErrorPage) onClearErrorPage();
  };

  disconnectCallback = () => {
    const { subscriptionClient } = this.state;
    const {
      token,
      isForbiddenRouteErrorPage,
      onClearErrorPage,
      onSetConnectionStatus,
    } = this.props;

    /* Ensures error pages are reset on logout */
    if (!token && isForbiddenRouteErrorPage) onClearErrorPage();

    /* Attempting to reconnect immediately after server disconnect */
    if (!subscriptionClient.closedByUser) {
      onSetConnectionStatus({
        wsConnectionStatus: wsConnectionStatusEnum.tryReconnect,
      });
      subscriptionClient.close(false, false);
    } else {
      /* Disconnect WS when user is offline */
      onSetConnectionStatus({
        wsConnectionStatus: wsConnectionStatusEnum.disconnected,
      });
    }
  };

  reconnectingCallback = () => {
    const { onSetConnectionStatus } = this.props;

    onSetConnectionStatus({
      wsConnectionStatus: wsConnectionStatusEnum.reconnecting,
    });
  };

  reconnectedCallback = () => {
    const { subscriptionClient } = this.state;
    const { onSetConnectionStatus } = this.props;

    /* Reinitialize apollo client to trigger queries refetch */
    this.createApolloClient(subscriptionClient);

    onSetConnectionStatus({
      wsConnectionStatus: wsConnectionStatusEnum.connected,
    });
  };

  render() {
    const { apolloClient } = this.state;
    const { children } = this.props;

    if (!apolloClient) return null;

    return (
      <BaseApolloProvider client={apolloClient}>{children}</BaseApolloProvider>
    );
  }
}

const mapStateToProps = (state) => {
  const isForbiddenRouteErrorPage = errorPageContent(state).statusCode === 403;

  return {
    token: userSelectors.accessToken(state),
    isForbiddenRouteErrorPage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onSetConnectionStatus: apolloActions.setWsConnectionStatus,
      onSetIsAuthClientReady: apolloActions.setIsAuthClientReady,
      onLogOut: userActions.logOutRequest,
      onClearErrorPage: errorPageActions.clearErrorPage,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ApolloProvider);
