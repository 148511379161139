import { useMutation } from "@apollo/client";
import { useCallback, useEffect } from "react";

import FloatingCallWidgetContainer from "@/components/Call/FloatingCallWidgetContainer";
import InboxPageSubscriptions from "@/components/InboxPage/InboxPageSubscriptions";
import ServiceStatusIndicator from "@/components/ServiceStatusIndicator";
import { useAuthContext } from "@/contextProviders/AuthProvider";
import { useServiceStatus } from "@/contextProviders/ServiceStatusProvider";
import { meUserDefinition } from "@/definitions/meDefinitions";
import { CREATE_WEB_PUSH_DEVICE } from "@/mutations/pushNotificationMutation";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { useValueRef } from "@/utils/hookUtils";
import * as pushNotificationUtils from "@/utils/pushNotificationUtils";

const AppLayout = ({ children }) => {
  const { isUserLoggedIn } = useAuthContext();
  const { isServiceStatusIndicatorShown } = useServiceStatus();

  const { data: meQueryData } = useMeQuery({ objectShape: meUserDefinition });
  const { user = {} } = meQueryData?.me || {};

  const [createWebPushDevice] = useMutation(CREATE_WEB_PUSH_DEVICE, {
    fetchPolicy: "no-cache",
  });

  const getSubscription = async (registration) => {
    const existingSubscription =
      await registration.pushManager.getSubscription();

    if (existingSubscription) {
      return existingSubscription;
    }

    return await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: pushNotificationUtils.getPublicVapidKey(),
    });
  };

  const createNotificationSubscription = useCallback(async () => {
    const registration = await navigator.serviceWorker.register("/sw.js");

    await navigator.serviceWorker.ready;

    const subscription = getSubscription(registration);

    return subscription;
  }, []);

  const getNotificationPermission = async () => {
    const status = await Notification.requestPermission();

    if (status === "denied") {
      return false;
    }

    return true;
  };

  const subscribeToPushNotification = useCallback(async () => {
    let subscription = await createNotificationSubscription();

    createWebPushDevice({
      variables: {
        input: pushNotificationUtils.prepareCreateWebPushDeviceInput({
          user,
          subscription,
        }),
      },
    });
  }, [user, createNotificationSubscription, createWebPushDevice]);

  const valueRefs = useValueRef({ subscribeToPushNotification });

  useEffect(() => {
    const isPushNotificationSupported =
      "serviceWorker" in navigator && "PushManager" in window;

    if (isUserLoggedIn) {
      if (!isPushNotificationSupported) {
        console.log("Push notification isn't supported in this browser.");
        return;
      }

      (async () => {
        const permission = await getNotificationPermission();
        const hasServiceWorker =
          await navigator.serviceWorker.getRegistration();

        if (hasServiceWorker || !permission) {
          return;
        }

        await valueRefs.current.subscribeToPushNotification();
      })().catch(console.error);
    }
  }, [valueRefs, isUserLoggedIn]);

  return (
    <>
      {isServiceStatusIndicatorShown && <ServiceStatusIndicator />}

      {children}

      {isUserLoggedIn && (
        <>
          <InboxPageSubscriptions />

          {/* Need to render this here so the widget container can move across whole page */}
          <FloatingCallWidgetContainer />
        </>
      )}
    </>
  );
};

export default AppLayout;
