const namespace = "@/apolloActions";

export const SET_AUTH_CLIENT_READY = `${namespace}/SET_AUTH_CLIENT_READY`;
export const SET_WS_CONNECTION_STATUS = `${namespace}/SET_WS_CONNECTION_STATUS`;

export const setIsAuthClientReady = ({ isAuthClientReady }) => ({
  type: SET_AUTH_CLIENT_READY,
  isAuthClientReady,
});

export const setWsConnectionStatus = ({ wsConnectionStatus }) => ({
  type: SET_WS_CONNECTION_STATUS,
  wsConnectionStatus,
});
