import {
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from "@apollo/client";
import { isEmpty, isNil } from "lodash";
import { requestMediaPermissions } from "mic-check";
import moment from "moment";
import NexmoClient from "nexmo-client";
import { useRouter } from "next/router";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import * as conversationActions from "@/actions/conversationActions";
import * as voiceCallActions from "@/actions/voiceCallActions";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import * as conversationListConversationDefinitions from "@/definitions/conversation/conversationListConversationDefinitions";
import { meUserIdDefinition } from "@/definitions/meDefinitions";
import allowedInboxViewEnum, {
  allowedInboxViewSectionEnum,
} from "@/enums/allowedInboxViewEnum";
import { cachedEventsTargetObjectEnum } from "@/enums/cacheEnum";
import { conversationStatusEnum } from "@/enums/conversation";
import { severityEnum } from "@/enums/styleVariantEnum";
import { dataObjectTypenameEnum } from "@/enums/typename";
import {
  ongoingVoiceCallStatuses,
  voiceCallParticipantTypeEnum,
  voiceLegTypeEnum,
} from "@/enums/voiceCall";
import { clickToCallExtensionMessageDataTypeEnum } from "@/enums/voiceCall/clickToCallExtensionMessageDataTypeEnum";
import * as inboxPageMutations from "@/mutations/inboxPageMutations";
import * as voiceCallMutations from "@/mutations/voiceCallMutations";
import * as featurePlanQueries from "@/queries/featurePlanQueries";
import * as voiceCallQueries from "@/queries/voiceCallQueries";
import * as privateVideoCallSelectors from "@/selectors/privateVideoCallSelectors";
import * as voiceCallSelectors from "@/selectors/voiceCallSelectors";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import * as commonUtils from "@/utils/commonUtils";
import { useValueRef } from "@/utils/hookUtils";
import * as inboxPageRoutingUtils from "@/utils/inboxPageRoutingUtils";
import { removeConversationFromQueryCache } from "@/utils/inboxPageUtils";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import { useAuthContext, useAuthenticatedUserContext } from "../AuthProvider";
import { useLeaderElection } from "../LeaderElectionProvider";

const VoiceCallProviderContext = createContext({});
const VoiceCallButtonContext = createContext({});
const VoiceCallMeetingRoomContext = createContext({});
const JoinVoiceCallButtonContext = createContext({});
const VoiceCallInvitationModalContext = createContext({});
const LeaveVoiceCallModalContext = createContext({});
const FloatingCallWidgetContainerContext = createContext({});
const ConversationEventSubscriptionContext = createContext({});

const VoiceCallProvider = ({ children }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const router = useRouter();

  const { selectedContactId } = router?.query || {};

  const { onSetAppSnackbarProps } = useAppSnackbar();
  const { isUserLoggedIn } = useAuthContext();
  const { isHiddenSupportUser } = useAuthenticatedUserContext();
  const {
    isVoiceFeatureLeaderTab,
    isVoiceFeatureUsedInAnotherTab,
    onVoiceFeatureLock,
  } = useLeaderElection();

  const [nexmoClient, setNexmoClient] = useState(null);
  const [voiceApp, setVoiceApp] = useState(null);
  const [isVoiceClientReady, setIsVoiceClientReady] = useState(false);

  const [isVoiceCallInvitationModalShown, setIsVoiceCallInvitationModalShown] =
    useState(false);
  const [isLeaveVoiceCallModalShown, setIsLeaveVoiceCallModalShown] =
    useState(false);

  const currentConversationId = useMemo(() => {
    return inboxPageRoutingUtils.getCurrentConversationIdFromRouter({
      pathname: router.pathname,
      params: router.query.params,
      strict: true,
    });
  }, [router.pathname, router.query.params]);

  const isVideoCallInUse = useSelector(
    privateVideoCallSelectors.isVideoCallInUse,
  );

  const conversationIdStartingVoiceCall = useSelector(
    voiceCallSelectors.conversationIdStartingVoiceCall,
  );
  const conversationIdSwitchingCallTo = useSelector(
    voiceCallSelectors.conversationIdSwitchingCallTo,
  );
  const conversationIdReconnectingVoiceCallMonitor = useSelector(
    voiceCallSelectors.conversationIdReconnectingVoiceCallMonitor,
  );
  const activeVoiceCallConversationId = useSelector(
    voiceCallSelectors.activeVoiceCallConversationId,
  );
  const ongoingVoiceCalls = useSelector(voiceCallSelectors.ongoingVoiceCalls);
  const {
    voiceConversation: currentConversationVoiceCall,
    cancelVoiceCallInviteState,
  } =
    useSelector(voiceCallSelectors.ongoingVoiceCall(currentConversationId)) ||
    {};
  const {
    voiceConversation: activeConversationVoiceCall,
    isEndingCall: isEndingActiveVoiceCall,
  } =
    useSelector(
      voiceCallSelectors.ongoingVoiceCall(activeVoiceCallConversationId),
    ) || {};

  const isVoiceCallInUse = useSelector(voiceCallSelectors.isVoiceCallInUse);

  const { data: voiceFeatureData, loading: isVoiceFeatureDataLoading } =
    useQuery(voiceCallQueries.GET_VOICE_FEATURE_DATA, {
      skip: !isUserLoggedIn,
    });

  const { voiceProviderAccounts = [], voiceConfiguration } =
    voiceFeatureData || {};

  const autoSelectOutboundCallGroups = useMemo(() => {
    return voiceFeatureData?.me?.groups || [];
  }, [voiceFeatureData?.me?.groups]);

  const { data: meQueryData } = useMeQuery({ objectShape: meUserIdDefinition });
  const { id: userId } = meQueryData?.me || {};

  const { data: featurePlansQueryData } = useQuery(
    featurePlanQueries.GET_VOICE_FEATURE_PLAN,
    { skip: !isUserLoggedIn },
  );
  const { isVoiceFeatureEnabled } = featurePlansQueryData?.featurePlan || {};

  const { data: lastVoiceCallData, loading: lastVoiceCallLoading } = useQuery(
    voiceCallQueries.GET_LAST_VOICE_CALL,
    {
      skip: !isUserLoggedIn || !currentConversationId,
      variables: { id: currentConversationId },
    },
  );

  const { lastVoiceConversation: currentConversationLastVoiceConversation } =
    lastVoiceCallData?.conversations?.results?.[0] || {};

  const isMyParticipantInCurrentConvoVoiceCall = useMemo(() => {
    return Boolean(
      voiceCallUtils.getParticipantFromVoiceCall({
        voiceCallParticipants:
          currentConversationLastVoiceConversation?.currentParticipants,
        participantType: voiceCallParticipantTypeEnum.agent,
        participantId: userId,
      }),
    );
  }, [currentConversationLastVoiceConversation, userId]);

  /* Does the conversation the agent is viewing have an ongoing call */
  const isFocusedOnConversationWithOngoingVoiceCall = useMemo(() => {
    const isOngoing = ongoingVoiceCallStatuses.includes(
      currentConversationLastVoiceConversation?.voiceCallStatus,
    );
    return currentConversationId && isOngoing;
  }, [
    currentConversationLastVoiceConversation?.voiceCallStatus,
    currentConversationId,
  ]);

  const isAgentMonitoringAnyCall = useMemo(
    () =>
      ongoingVoiceCalls.some(
        ({ voiceConversation }) =>
          voiceConversation?.lastVoiceLegOfMe?.legType ===
          voiceLegTypeEnum.CALL_MONITOR,
      ),
    [ongoingVoiceCalls],
  );

  const isAgentInAnyCall = ongoingVoiceCalls.length > 0;
  const isMultiCall = ongoingVoiceCalls.length > 1;

  const isStartingVoiceCall = !!conversationIdStartingVoiceCall;
  const isReconnectingVoiceCallMonitor =
    !!conversationIdReconnectingVoiceCallMonitor;
  const isSwitchingActiveVoiceCall = !!conversationIdSwitchingCallTo;
  const isAnyVoiceCallStarting =
    isStartingVoiceCall || isSwitchingActiveVoiceCall;
  const hasOngoingNonMonitoredVoiceCall =
    isAgentInAnyCall && !isAgentMonitoringAnyCall;

  /* Is the agent in the call for the conversation they are viewing */
  const isAgentInCurrentConvoVoiceCallInCurrentTab =
    !!currentConversationVoiceCall;

  const isAgentInCurrentConvoVoiceCallInAnotherTab =
    isMyParticipantInCurrentConvoVoiceCall && isVoiceFeatureUsedInAnotherTab;

  const valueRefs = useValueRef({
    router,
    nexmoClient,
    ongoingVoiceCalls,
    autoSelectOutboundCallGroups,
    voiceConfiguration,
    voiceProviderAccounts,
    isAgentInAnyCall,
    isVoiceFeatureDataLoading,
    isVoiceFeatureLeaderTab,
    onVoiceFeatureLock,
  });

  /*
    This is true when the agent is focused on a conversation that has an ongoing call
    but they are not in the call themselves (call data is not in reducer)
  */
  const isCurrentConvoVoiceCallWithSomeoneElse =
    isFocusedOnConversationWithOngoingVoiceCall &&
    !isAgentInCurrentConvoVoiceCallInAnotherTab &&
    !isAgentInCurrentConvoVoiceCallInCurrentTab;

  const [createVoiceJwt] = useMutation(voiceCallMutations.CREATE_VOICE_JWT, {
    onCompleted: ({ createVonageJwt }) => {
      const { jwt } = createVonageJwt;

      nexmoClient
        .createSession(jwt)
        .then((app) => setVoiceApp(app))
        .catch((error) => {
          console.error("Unable to log into voice provider", error);
        });
    },
    onError: () => {
      console.error("Unable to create token for voice provider");
    },
  });

  const [
    inviteToVoiceConversation,
    { loading: isInviteToVoiceConversationLoading },
  ] = useMutation(voiceCallMutations.INVITE_TO_VOICE_CONVERSATION);

  const [
    cancelVoiceConversationInvite,
    { loading: cancelVoiceConversationInviteLoading },
  ] = useMutation(voiceCallMutations.BULK_CANCEL_VOICE_CONVERSATION_INVITE, {
    onError: ({ message }) => setErrorMessageSnackbar(message),
  });

  const [transferConversation, { loading: isAgentTransferLoading }] =
    useMutation(inboxPageMutations.TRANSFER_CONVERSATION, {
      onCompleted: ({ transferConversation }) => {
        const { conversation } = transferConversation;
        handleConversationTransferSuccess(conversation);
      },
      fetchPolicy: "no-cache",
    });

  const [muteVoiceLeg, { loading: muteVoiceLegLoading }] = useMutation(
    voiceCallMutations.MUTE_VOICE_LEG,
  );
  const [unmuteVoiceLeg, { loading: unmuteVoiceLegLoading }] = useMutation(
    voiceCallMutations.UNMUTE_VOICE_LEG,
  );
  const isMuteActionLoading = muteVoiceLegLoading || unmuteVoiceLegLoading;

  const [holdVoiceCall, { loading: holdVoiceCallLoading }] = useMutation(
    voiceCallMutations.HOLD_VOICE_CONVERSATION,
  );
  const [unHoldVoiceCall, { loading: unHoldVoiceCallLoading }] = useMutation(
    voiceCallMutations.UN_HOLD_VOICE_CONVERSATION,
  );
  const isHoldActionLoading = holdVoiceCallLoading || unHoldVoiceCallLoading;

  const [switchAwayFromVoiceCall] = useMutation(
    voiceCallMutations.SWITCH_AWAY_FROM_VOICE_CONVERSATION,
  );
  const [switchToVoiceCall] = useMutation(
    voiceCallMutations.SWITCH_TO_VOICE_CONVERSATION,
  );

  useSubscription(
    voiceCallQueries.DEFAULT_OUTBOUND_CALL_VPA_SETTINGS_SUBSCRIPTION,
    { skip: !isUserLoggedIn },
  );

  const handleResetVoiceCallStates = () => {
    setNexmoClient(null);
    setVoiceApp(null);
    setIsVoiceClientReady(false);
  };

  /* Change feature leader tab when the tab has voice call in use */
  useEffect(() => {
    valueRefs.current.onVoiceFeatureLock({ isFeatureInUse: isVoiceCallInUse });
  }, [valueRefs, isVoiceCallInUse]);

  /* Add confirmation before refresh when the agent in a call */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const { isAgentInAnyCall } = valueRefs.current;

      /* Do nothing if agent has no ongoing voice calls */
      if (!isAgentInAnyCall) return;

      event.preventDefault();
      event.returnValue =
        "Ongoing voice call will be terminated, do you want to proceed?";

      return "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [valueRefs]);

  /* Effect to create the nexmo-client instance */
  useEffect(() => {
    if (!isUserLoggedIn) return;
    if (!isVoiceFeatureEnabled) return;
    if (isHiddenSupportUser) return;
    if (isEmpty(voiceConfiguration)) return;
    if (valueRefs.current.nexmoClient) return;

    const { vonageApiBaseUrl, vonageWebsocketUrl } = voiceConfiguration;
    const nexmoConfig = {
      nexmo_api_url: vonageApiBaseUrl,
      url: vonageWebsocketUrl,
      ips_url: `${vonageApiBaseUrl}/v1/image`,
    };

    const client = new NexmoClient(nexmoConfig);

    client.on("ready", () => {
      setIsVoiceClientReady(true);
    });

    client.on("disconnect", () => {
      handleResetVoiceCallStates();
    });

    setNexmoClient(client);
  }, [
    valueRefs,
    isUserLoggedIn,
    isVoiceFeatureEnabled,
    isHiddenSupportUser,
    voiceConfiguration,
  ]);

  useEffect(() => {
    if (!nexmoClient) return;

    if (isUserLoggedIn && !isVoiceClientReady) {
      createVoiceJwt();
    }

    /* Cleanup for when the user logs out of the app */
    if (!isUserLoggedIn && isVoiceClientReady) {
      nexmoClient
        .deleteSession()
        .then(() => handleResetVoiceCallStates())
        .catch((error) => {
          console.error("Unable to log out of voice provider", error);
        });
    }
  }, [isUserLoggedIn, isVoiceClientReady, nexmoClient, createVoiceJwt]);

  useEffect(() => {
    if (!currentConversationId) return;

    dispatch(
      conversationActions.setIsVoiceCallWithOtherAgent({
        isVoiceCallWithOtherAgent: isCurrentConvoVoiceCallWithSomeoneElse,
      }),
    );
  }, [currentConversationId, isCurrentConvoVoiceCallWithSomeoneElse, dispatch]);

  /* Notify & update click-to-call extension when active voice call object data changes */
  useEffect(() => {
    if (!valueRefs.current.isVoiceFeatureLeaderTab) return;

    if (!activeConversationVoiceCall) {
      window.postMessage(
        {
          type: clickToCallExtensionMessageDataTypeEnum.REMOVE_ACTIVE_VOICE_CALL_DATA,
        },
        window.location.origin,
      );
      return;
    }

    const isVoiceCallOngoing = ongoingVoiceCallStatuses.includes(
      activeConversationVoiceCall.voiceCallStatus,
    );

    const { clickToCallExtensionVoiceCallObj } =
      voiceCallUtils.getClickToCallExtensionVoiceCallObj({
        voiceConversation: activeConversationVoiceCall,
        userId,
        /*
          Only pass loading states when there is an ongoing call.
          If there is no ongoing call, reset loading states to false
        */
        ...(isVoiceCallOngoing && {
          isStartingVoiceCall,
          isMuteActionLoading,
          isEndActionLoading: isEndingActiveVoiceCall,
        }),
      });

    window.postMessage(
      {
        type: clickToCallExtensionMessageDataTypeEnum.SYNC_ACTIVE_VOICE_CALL_DATA,
        clickToCallExtensionVoiceCallObj,
      },
      window.location.origin,
    );
  }, [
    valueRefs,
    activeConversationVoiceCall,
    userId,
    isStartingVoiceCall,
    isMuteActionLoading,
    isEndingActiveVoiceCall,
  ]);

  const handleRequestVPAData = async ({
    selectedPhoneNumber,
    selectedGroupId,
  }) => {
    try {
      if (!selectedPhoneNumber) return;
      if (valueRefs.current.isVoiceFeatureDataLoading) return;

      const vpaMenuOptions = voiceCallUtils.getAutoSelectVPAArrayInClickToCall({
        selectedPhoneNumber,
        selectedGroupId,
        groups: valueRefs.current.autoSelectOutboundCallGroups,
        voiceConfiguration: valueRefs.current.voiceConfiguration,
        voiceProviderAccounts: valueRefs.current.voiceProviderAccounts,
      });

      window.postMessage(
        {
          type: clickToCallExtensionMessageDataTypeEnum.RETURN_VPA_DATA_SUCCESS,
          vpaMenuOptions,
        },
        window.location.origin,
      );
    } catch (error) {
      window.postMessage(
        {
          type: clickToCallExtensionMessageDataTypeEnum.RETURN_VPA_DATA_ERROR,
          errorMessage: error.message,
        },
        window.location.origin,
      );
    }
  };

  const handleReturnClickToCallSettingsData = () => {
    if (valueRefs.current.isVoiceFeatureDataLoading) return;

    const clickToCallExtensionSettingsData = {
      groups: valueRefs.current.autoSelectOutboundCallGroups,
      voiceProviderAccounts: valueRefs.current.voiceProviderAccounts,
    };

    window.postMessage(
      {
        type: clickToCallExtensionMessageDataTypeEnum.RETURN_CLICK_TO_CALL_SETTINGS_DATA_SUCCESS,
        clickToCallExtensionSettingsData,
      },
      window.location.origin,
    );
  };

  const handleReturnAutoSelectOutboundCallSettingsData = ({
    selectedGroupId,
  }) => {
    if (valueRefs.current.isVoiceFeatureDataLoading) return;

    const {
      isAutoSelectOutboundCallVPA,
      defaultOutboundCallVoiceProviderAccount,
    } = voiceCallUtils.getOutboundCallVPASettings({
      selectedGroupId,
      groups: valueRefs.current.autoSelectOutboundCallGroups,
      voiceConfiguration: valueRefs.current.voiceConfiguration,
      voiceProviderAccounts: valueRefs.current.voiceProviderAccounts,
    });

    const autoSelectOutboundCallSettings = {
      voiceProviderAccounts: valueRefs.current.voiceProviderAccounts,
      defaultOutboundCallVoiceProviderAccount,
      isAutoSelectOutboundCallVPA,
    };

    window.postMessage(
      {
        type: clickToCallExtensionMessageDataTypeEnum.RETURN_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA_SUCCESS,
        autoSelectOutboundCallSettings,
      },
      window.location.origin,
    );
  };

  const handleStartVoiceCallByClickToCallExtension = async ({
    contactPhoneNumber,
    voiceProviderAccountId,
    groupId,
  }) => {
    try {
      if (!contactPhoneNumber || !voiceProviderAccountId) return;

      const { data } = await client.mutate({
        mutation: voiceCallMutations.CREATE_CONVERSATION_WITH_PHONE_NUMBER,
        variables: { input: { groupId, phoneNumber: contactPhoneNumber } },
      });

      startVoiceCall({
        callType: voiceLegTypeEnum.VOICE_CALL,
        conversationId: data.createConversationWithPhoneNumber.conversation.id,
        voiceProviderContactId: contactPhoneNumber,
        voiceProviderAccountId,
        handleErrorMessage: (errorMessage) => {
          window.postMessage(
            {
              type: clickToCallExtensionMessageDataTypeEnum.RETURN_START_VOICE_CALL_ERROR,
              errorMessage,
            },
            window.location.origin,
          );
        },
      });
    } catch (error) {
      window.postMessage(
        {
          type: clickToCallExtensionMessageDataTypeEnum.RETURN_START_VOICE_CALL_ERROR,
          errorMessage: `Failed to start call - ${error}`,
        },
        window.location.origin,
      );
    }
  };

  const handleEndVoiceCallByClickToCallExtension = async ({
    conversationId,
  }) => {
    if (!conversationId) return;

    await endVoiceCall({
      conversationId,
      isClickToCallExtension: true,
      handleErrorMessage: (errorMessage) => {
        window.postMessage(
          {
            type: clickToCallExtensionMessageDataTypeEnum.RETURN_END_VOICE_CALL_ERROR,
            errorMessage,
          },
          window.location.origin,
        );
      },
    });
  };

  const handleToggleMicrophoneByClickToCallExtension = ({ conversationId }) => {
    if (!conversationId) return;

    const participantType = voiceCallParticipantTypeEnum.agent;

    toggleMicrophone({
      conversationId,
      participantType,
      participantId: userId,
      handleErrorMessage: (errorMessage) => {
        window.postMessage(
          {
            type: clickToCallExtensionMessageDataTypeEnum.RETURN_TOGGLE_MICROPHONE_ERROR,
            errorMessage,
          },
          window.location.origin,
        );
      },
    });
  };

  const windowMessageEventCallbacksRef = useValueRef({
    handleRequestVPAData,
    handleStartVoiceCallByClickToCallExtension,
    handleEndVoiceCallByClickToCallExtension,
    handleToggleMicrophoneByClickToCallExtension,
    handleReturnClickToCallSettingsData,
    handleReturnAutoSelectOutboundCallSettingsData,
  });

  const isVoiceCallAvailable =
    Boolean(voiceApp) &&
    voiceProviderAccounts.length > 0 &&
    !lastVoiceCallLoading &&
    isVoiceFeatureLeaderTab;

  useEffect(() => {
    if (!isVoiceFeatureEnabled) return;
    if (!isUserLoggedIn) return;
    if (!isVoiceFeatureLeaderTab) return;
    if (!isVoiceCallAvailable) return;

    const handleReceiveWindowMessageEvent = (event) => {
      const isTrustedPostMessageEvent =
        commonUtils.checkIsTrustedPostMessageEvent(event);

      if (!isTrustedPostMessageEvent) return;

      switch (event.data.type) {
        case clickToCallExtensionMessageDataTypeEnum.REQUEST_VPA_DATA: {
          const { selectedPhoneNumber, selectedGroupId } = event.data;

          windowMessageEventCallbacksRef.current.handleRequestVPAData({
            selectedPhoneNumber,
            selectedGroupId,
          });
          break;
        }

        case clickToCallExtensionMessageDataTypeEnum.REQUEST_START_VOICE_CALL: {
          const { contactPhoneNumber, voiceProviderAccountId, groupId } =
            event.data;

          windowMessageEventCallbacksRef.current.handleStartVoiceCallByClickToCallExtension(
            {
              contactPhoneNumber,
              voiceProviderAccountId,
              groupId,
            },
          );
          break;
        }

        case clickToCallExtensionMessageDataTypeEnum.REQUEST_END_VOICE_CALL: {
          const { conversationId } = event.data;
          windowMessageEventCallbacksRef.current.handleEndVoiceCallByClickToCallExtension(
            { conversationId },
          );
          break;
        }

        case clickToCallExtensionMessageDataTypeEnum.REQUEST_TOGGLE_MICROPHONE: {
          const { conversationId } = event.data;
          windowMessageEventCallbacksRef.current.handleToggleMicrophoneByClickToCallExtension(
            { conversationId },
          );
          break;
        }

        case clickToCallExtensionMessageDataTypeEnum.REQUEST_CLICK_TO_CALL_SETTINGS_DATA: {
          windowMessageEventCallbacksRef.current.handleReturnClickToCallSettingsData();
          break;
        }

        case clickToCallExtensionMessageDataTypeEnum.REQUEST_AUTO_SELECT_OUTBOUND_CALL_SETTINGS_DATA: {
          const { selectedGroupId } = event.data;
          windowMessageEventCallbacksRef.current.handleReturnAutoSelectOutboundCallSettingsData(
            { selectedGroupId },
          );
          break;
        }
      }
    };

    window.addEventListener("message", handleReceiveWindowMessageEvent);

    window.postMessage(
      {
        type: clickToCallExtensionMessageDataTypeEnum.NOTIFY_MESSAGE_EVENT_LISTENER_ADDED,
      },
      window.location.origin,
    );

    return () => {
      window.removeEventListener("message", handleReceiveWindowMessageEvent);
    };
  }, [
    windowMessageEventCallbacksRef,
    isVoiceFeatureEnabled,
    isVoiceFeatureLeaderTab,
    isUserLoggedIn,
    isVoiceCallAvailable,
  ]);

  const setErrorMessageSnackbar = useCallback(
    (message) => {
      onSetAppSnackbarProps({
        message,
        AlertProps: { severity: severityEnum.error },
      });
    },
    [onSetAppSnackbarProps],
  );

  const setConversationStartingVoiceCall = useCallback(
    (conversationId = null) => {
      dispatch(
        voiceCallActions.setConversationStartingVoiceCall({ conversationId }),
      );
    },
    [dispatch],
  );

  const setConversationReconnectingVoiceCallMonitor = useCallback(
    (conversationId = null) => {
      dispatch(
        voiceCallActions.setConversationReconnectingVoiceCallMonitor({
          conversationId,
        }),
      );
    },
    [dispatch],
  );

  const setConversationIdSwitchingVoiceCallTo = useCallback(
    (conversationId = null) => {
      dispatch(
        voiceCallActions.setConversationIdSwitchingVoiceCallTo({
          conversationId,
        }),
      );
    },
    [dispatch],
  );

  const setActiveVoiceCallConversationId = useCallback(
    (conversationId = null) => {
      dispatch(
        voiceCallActions.setActiveVoiceCallConversationId({ conversationId }),
      );
    },
    [dispatch],
  );

  const setConversationIdStartAndSwitchVoiceCall = useCallback(
    (conversationId) => {
      setConversationStartingVoiceCall(conversationId);
      setConversationIdSwitchingVoiceCallTo(conversationId);
    },
    [setConversationStartingVoiceCall, setConversationIdSwitchingVoiceCallTo],
  );

  /*
    Used to get a specified nexmo call object

    Important note: The "Application" class in the nexmo-client doesn't currently have
    a class method to get a specific call, so this function can serve that purpose for us.
    If the implementation of the Application class should ever change in future versions
    we will need to revise this.
  */
  const getNexmoCall = useCallback(
    (vonageConversationId) => {
      if (!voiceApp) return;
      if (!vonageConversationId) return;
      return voiceApp.calls.get(vonageConversationId);
    },
    [voiceApp],
  );

  const getCallObjFromOngoingCalls = useCallback(
    (conversationId) => {
      return valueRefs.current.ongoingVoiceCalls.find(
        (callObj) => callObj.conversationId === conversationId,
      );
    },
    [valueRefs],
  );

  const handleOpenVoiceCallInvitationModal = useCallback(() => {
    setIsVoiceCallInvitationModalShown(true);
  }, []);

  const handleCloseVoiceCallInvitationModal = useCallback(() => {
    setIsVoiceCallInvitationModalShown(false);
  }, []);

  const handleOpenLeaveVoiceCallModal = () => {
    setIsLeaveVoiceCallModalShown(true);
  };

  const handleCloseLeaveVoiceCallModal = useCallback(() => {
    setIsLeaveVoiceCallModalShown(false);
  }, []);

  const checkBrowserMicrophonePermission = ({
    onPermissionAllowed,
    onPermissionDenied,
  }) => {
    requestMediaPermissions({ audio: true, video: false })
      .then(onPermissionAllowed)
      .catch(onPermissionDenied);
  };

  /* Handle routing user to the conversation with the call in inbox page */
  const handleConversationSwitch = useCallback(
    (conversationId) => {
      const isSameConversation = conversationId === currentConversationId;
      if (isSameConversation) return;

      const queryValuesToUpdate = (() => {
        /* If no conversationId given, only remove focus from the conversation */
        if (!conversationId) return { params: "" };

        /* Get the conversation object from the cache */
        const cachedConversation = client.readFragment({
          id: `${dataObjectTypenameEnum.conversationObject}:${conversationId}`,
          fragment:
            conversationListConversationDefinitions.CACHED_CONVERSATION_LIST_ITEM_FRAGMENT,
          variables: {
            targetEventsObject: cachedEventsTargetObjectEnum.allEvents,
          },
        });

        /* Check if the inbox view is valid for the conversation we want to go to */
        const { isInboxViewStillValid } = cachedConversation
          ? inboxPageRoutingUtils.validateAllowedInboxView({
              allowedInboxView: cachedConversation.allowedInboxView,
              conversationStatus: cachedConversation.conversationStatus,
              routerQuery: valueRefs.current.router.query,
            })
          : { isInboxViewStillValid: false };

        /* If inbox view is still valid, only update the conversation id */
        if (isInboxViewStillValid) {
          return { params: [conversationId] };
        } else {
          /* Otherwise, update the conversation id as well as the current inbox view*/
          return {
            inboxViewSection: allowedInboxViewSectionEnum.special,
            inboxViewId: allowedInboxViewEnum.all,
            status: conversationStatusEnum.active,
            params: [conversationId],
          };
        }
      })();

      inboxPageRoutingUtils.goToInboxPageWithRouter({
        router: valueRefs.current.router,
        queryValuesToUpdate,
      });
      dispatch(conversationActions.resetConversation());
    },
    [valueRefs, client, currentConversationId, dispatch],
  );

  const handleConversationTransferSuccess = (conversation) => {
    const { allowedInboxView, conversationStatus, assignee } = conversation;
    const isTransferToSelf = assignee.id === userId;

    const routerQuery = router?.query || {};
    const { inboxViewSection, inboxViewId } = routerQuery;

    const { isInboxViewStillValid } =
      inboxPageRoutingUtils.validateAllowedInboxView({
        allowedInboxView,
        conversationStatus,
        routerQuery,
      });

    const isAgentAllowedToViewConversation =
      allowedInboxView.special.length > 0;

    const shouldRemoveConversation =
      (selectedContactId && !isAgentAllowedToViewConversation) ||
      (!selectedContactId && !isInboxViewStillValid);

    handleCloseLeaveVoiceCallModal();

    /* Remove the ongoing voice call only if agent transfer conversation to other agent */
    if (!isTransferToSelf) {
      dispatch(
        voiceCallActions.removeOngoingVoiceCall({
          conversationId: conversation.id,
        }),
      );
    }

    if (shouldRemoveConversation) {
      removeConversationFromQueryCache({
        client,
        conversation,
        inboxViewSection,
        inboxViewId,
        contactId: selectedContactId,
      });
      handleConversationSwitch();
    }
  };

  const handleConversationTransfer = useCallback(
    async ({ conversationId, agentId, groupId }) => {
      try {
        const { data: { agents = {} } = {} } = await client.query({
          query: voiceCallQueries.GET_AGENT_IN_GROUP,
          fetchPolicy: "no-cache",
          variables: {
            id: agentId,
            groups: groupId ? [groupId] : [],
          },
        });

        const input = { conversationId, agentId };

        const isAgentInGroup = agents.totalCount === 1;

        /* Retain conversation group if the new assigned agent is also in the group */
        if (isAgentInGroup) input.groupId = groupId;

        await transferConversation({ variables: { input } });
      } catch (error) {
        setErrorMessageSnackbar(`Failed to transfer conversation: ${error}`);
      }
    },
    [client, setErrorMessageSnackbar, transferConversation],
  );

  /*
    This function is used to mute/unmute the audio input of call monitor to prevent a brief delay
    during which other users might be able to hear the voice before monitor gets muted
  */
  const toggleNexmoCallAudioInput = useCallback(
    ({ isAudioEnabled = false, nexmoCall, voiceConversation }) => {
      const nexmoCallObj = (() => {
        if (nexmoCall) return nexmoCall;

        if (voiceConversation) {
          const vonageClientConversationId =
            voiceConversation.voiceProviderConversation.clientConversationId;
          return getNexmoCall(vonageClientConversationId);
        }
      })();

      if (!nexmoCallObj) return;

      /* Use the media stream API inside the built-in rtcObjects to mute the audio track */
      const rtcObj = Object.values(nexmoCallObj.rtcObjects)[0];

      if (!rtcObj || !rtcObj?.stream) return;

      rtcObj.stream.getAudioTracks().forEach((track) => {
        /*
        Preserve the audio track state for subsequent mute calls.
        Initially conversation object is null when running callServer for call monitor.

        Important note: If the implementation of the NXMCall class should ever change
        in future versions we will need to revise this.
      */
        if (nexmoCallObj.conversation && track.enabled) return;
        track.enabled = isAudioEnabled;
      });
    },
    [getNexmoCall],
  );

  /* Function responsible for removing an agent from a voice call */
  const leaveVonageConversation = useCallback(
    async ({
      conversationId,
      vonageConversationId,
      isCallMonitor = false,
      handleErrorMessage = setErrorMessageSnackbar,
    }) => {
      if (!voiceApp) return;
      if (!vonageConversationId) return;

      dispatch(
        voiceCallActions.setConversationIsEndingCall({
          conversationId,
          isEndingCall: true,
        }),
      );

      /* Attempt to leave the vonage conversation */
      try {
        const vonageConversation =
          await voiceApp.getConversation(vonageConversationId);
        await vonageConversation.leave();

        if (isLeaveVoiceCallModalShown) handleCloseLeaveVoiceCallModal();

        dispatch(voiceCallActions.removeOngoingVoiceCall({ conversationId }));
      } catch (error) {
        console.error("Unable to leave voice call", error);

        if (isCallMonitor) {
          setConversationReconnectingVoiceCallMonitor();
        }

        dispatch(
          voiceCallActions.setConversationIsEndingCall({
            conversationId,
            isEndingCall: false,
          }),
        );

        handleErrorMessage("Unable to end voice call");
      }
    },
    [
      voiceApp,
      isLeaveVoiceCallModalShown,
      dispatch,
      handleCloseLeaveVoiceCallModal,
      setConversationReconnectingVoiceCallMonitor,
      setErrorMessageSnackbar,
    ],
  );

  const endVoiceCall = useCallback(
    async ({
      conversationId,
      agentId,
      isCallRejection = false,
      isCallMonitor = false,
      isClickToCallExtension = false,
      handleErrorMessage = setErrorMessageSnackbar,
    }) => {
      if (!voiceApp) return;

      const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
      if (!voiceCallObj) return;

      const { isEndingCall, voiceConversation } = voiceCallObj;
      if (isEndingCall) {
        if (isCallMonitor) {
          setConversationReconnectingVoiceCallMonitor();
        }
        handleErrorMessage("Ending call in progress");
        return;
      }

      const { conversation, currentParticipants } = voiceConversation || {};

      const hasOtherAgentInCurrentConvoVoiceCall = !isEmpty(
        voiceCallUtils.getOtherActiveAgentsFromCall({
          currentParticipants,
          userId,
        }),
      );

      const isConversationAssignedToMe = userId === conversation?.assignee?.id;
      const shouldShowLeaveVoiceCallModal =
        !isClickToCallExtension &&
        !isCallRejection &&
        !isLeaveVoiceCallModalShown &&
        hasOtherAgentInCurrentConvoVoiceCall &&
        isConversationAssignedToMe;

      /*
      2 options of ending call:
        1. If agent leave call + transfer conversation to other agent, transfer conversation (auto end call)
        2. If agent leave call + keep conversation assigned to me, run conversation.leave
      Note:
        - Backend automatically handle conversation.leave when transfer mutation is executed
        - If call is ended by click-to-call extension (isClickToCallExtension = true), use 2nd option
    */
      if (isLeaveVoiceCallModalShown && agentId) {
        handleConversationTransfer({
          conversationId,
          agentId,
          groupId: conversation?.group?.id,
        });
        return;
      }

      if (shouldShowLeaveVoiceCallModal) {
        handleOpenLeaveVoiceCallModal();
        return;
      }

      const vonageConversationId = (() => {
        if (isCallRejection) {
          return voiceCallUtils.getClientAgentIpCallId(voiceCallObj);
        }

        return voiceCallObj.voiceConversation.voiceProviderConversation
          .clientConversationId;
      })();

      await leaveVonageConversation({
        conversationId,
        vonageConversationId,
        isCallMonitor,
        handleErrorMessage,
      });
    },
    [
      userId,
      voiceApp,
      isLeaveVoiceCallModalShown,
      getCallObjFromOngoingCalls,
      handleConversationTransfer,
      leaveVonageConversation,
      setConversationReconnectingVoiceCallMonitor,
      setErrorMessageSnackbar,
    ],
  );

  /*
      This function is responsible for:
      - Making outgoing call (Agent -> Contact)
      - Rejoining an ongoing call
      - Start monitoring an ongoing call
  */
  const startVoiceCall = useCallback(
    async ({
      callType,
      conversationId,
      voiceConversationId,
      voiceProviderContactId,
      voiceProviderAccountId,
      handleErrorMessage = setErrorMessageSnackbar,
    }) => {
      if (!voiceApp) return;

      if (isNil(voiceProviderContactId)) {
        handleErrorMessage(
          "Voice provider account ID is required to make a voice call",
        );
        return;
      }

      if (isStartingVoiceCall) {
        handleErrorMessage("Starting voice call in progress");
        return;
      }

      if (isVideoCallInUse) {
        handleErrorMessage("Can not start voice call while in video call");
        return;
      }

      const isStartingCallMonitor = callType === voiceLegTypeEnum.CALL_MONITOR;

      const shouldStopMonitoringActiveCall =
        isStartingCallMonitor && isAgentMonitoringAnyCall;

      if (isAgentInAnyCall) {
        /* If call-monitor agent want to monitor another call, leave the current monitored call then start the new monitored call */
        if (shouldStopMonitoringActiveCall) {
          setConversationReconnectingVoiceCallMonitor(
            activeVoiceCallConversationId,
          );

          await endVoiceCall({
            conversationId: activeVoiceCallConversationId,
            isCallMonitor: true,
          });
        } else {
          handleErrorMessage(
            "Can not make outbound calls while agent has ongoing calls",
          );
          return;
        }
      }

      const customData = (() => {
        const commonCustomData = { call_type: callType };

        switch (callType) {
          case voiceLegTypeEnum.VOICE_CALL: {
            return {
              conversation_id: conversationId,
              voice_provider_account_id: voiceProviderAccountId,
              ...commonCustomData,
            };
          }
          case voiceLegTypeEnum.CALL_MONITOR:
          case voiceLegTypeEnum.SELF_JOIN: {
            return {
              voice_conversation_id: voiceConversationId,
              ...commonCustomData,
            };
          }
          default:
            return {};
        }
      })();

      setConversationStartingVoiceCall(conversationId);

      voiceApp
        .callServer(voiceProviderContactId, "phone", customData)
        .then((nexmoCall) => {
          if (!isStartingCallMonitor) return;

          /*
          Due to Vonage API constraint, call monitor voice leg is not muted initially and is only
          muted during VOICE_CALL_LEG_MUTED event that is emitted after VOICE_CALL_MONITOR_STARTED.
          This ensures other agent cannot hear call monitor sound once callServer is initiated.
        */
          toggleNexmoCallAudioInput({ nexmoCall });

          /* Reset the state after successfully reconnected to call monitor */
          setConversationReconnectingVoiceCallMonitor();
        })
        .catch((error) => {
          console.error("Unable to start voice call", error);

          setConversationStartingVoiceCall();
          setActiveVoiceCallConversationId();

          if (isStartingCallMonitor) {
            setConversationReconnectingVoiceCallMonitor();
          }

          checkBrowserMicrophonePermission({
            onPermissionAllowed: () => {
              handleErrorMessage("Unable to start voice call");
            },
            onPermissionDenied: () => {
              handleErrorMessage(
                "Please allow microphone permission to start the call",
              );
            },
          });
        });
    },
    [
      voiceApp,
      activeVoiceCallConversationId,
      isAgentInAnyCall,
      isAgentMonitoringAnyCall,
      isStartingVoiceCall,
      isVideoCallInUse,
      setActiveVoiceCallConversationId,
      setConversationReconnectingVoiceCallMonitor,
      setConversationStartingVoiceCall,
      setErrorMessageSnackbar,
      toggleNexmoCallAudioInput,
      endVoiceCall,
    ],
  );

  /* Only agents in the call can invite others */
  const inviteParticipantToVoiceCall = useCallback(
    async ({
      voiceConversationId,
      targetAgentIds,
      targetVoiceCallContactCandidates,
    }) => {
      if (isInviteToVoiceConversationLoading) return;

      try {
        const result = await inviteToVoiceConversation({
          variables: {
            input: {
              voiceConversationId,
              targetAgentIds,
              targetVoiceCallContactCandidates,
            },
          },
        });

        const { conversation } =
          result.data.inviteToVoiceConversation.voiceConversation || {};

        handleCloseVoiceCallInvitationModal();

        dispatch(
          voiceCallActions.setCancelVoiceCallInviteState({
            conversationId: conversation.id,
            isCancelVoiceCallInviteShown: true,
            invitedAt: moment(),
          }),
        );
      } catch (error) {
        setErrorMessageSnackbar("Failed to invite participant(s)");
      }
    },
    [
      isInviteToVoiceConversationLoading,
      dispatch,
      handleCloseVoiceCallInvitationModal,
      inviteToVoiceConversation,
      setErrorMessageSnackbar,
    ],
  );

  const cancelVoiceCallInvite = useCallback(
    (conversationId) => {
      if (cancelVoiceConversationInviteLoading) return;

      const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
      if (!voiceCallObj) return;

      const { voiceConversation } = voiceCallObj;

      cancelVoiceConversationInvite({
        variables: {
          input: {
            voiceConversationId: voiceConversation.id,
          },
        },
      });
    },
    [
      cancelVoiceConversationInviteLoading,
      cancelVoiceConversationInvite,
      getCallObjFromOngoingCalls,
    ],
  );

  /* Responsible for turning mic on or off for a specified participant */
  const toggleMicrophone = useCallback(
    async ({
      conversationId,
      participantType,
      participantId,
      handleErrorMessage = setErrorMessageSnackbar,
    }) => {
      if (!conversationId) return;
      if (isMuteActionLoading) {
        handleErrorMessage("Mute action in progress");
        return;
      }

      const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
      if (!voiceCallObj) return;

      const { voiceConversation } = voiceCallObj;
      const { currentParticipants } = voiceConversation || {};
      const selectedParticipant = voiceCallUtils.getParticipantFromVoiceCall({
        voiceCallParticipants: currentParticipants,
        participantType,
        participantId,
      });

      if (!selectedParticipant) return;
      const { isMuted, voiceLeg } = selectedParticipant;
      const selectedFn = isMuted ? unmuteVoiceLeg : muteVoiceLeg;

      try {
        await selectedFn({ variables: { input: { voiceLegId: voiceLeg.id } } });
      } catch (error) {
        handleErrorMessage(error.message);
      }
    },
    [
      isMuteActionLoading,
      getCallObjFromOngoingCalls,
      muteVoiceLeg,
      setErrorMessageSnackbar,
      unmuteVoiceLeg,
    ],
  );

  /* Responsible for placing a call on hold */
  const toggleHoldCall = useCallback(
    (conversationId) => {
      if (!conversationId) return;
      if (isHoldActionLoading) {
        setErrorMessageSnackbar("Hold action in progress");
        return;
      }

      const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
      if (!voiceCallObj) return;

      const { voiceConversation } = voiceCallObj;
      const { id: voiceConversationId, isOnHold } = voiceConversation;
      const selectedFn = isOnHold ? unHoldVoiceCall : holdVoiceCall;
      selectedFn({ variables: { input: { voiceConversationId } } });
    },
    [
      isHoldActionLoading,
      getCallObjFromOngoingCalls,
      holdVoiceCall,
      setErrorMessageSnackbar,
      unHoldVoiceCall,
    ],
  );

  const switchAwayFromActiveCall = useCallback(async () => {
    if (!activeVoiceCallConversationId) return;
    if (isAgentMonitoringAnyCall) return;

    const voiceCallObj = getCallObjFromOngoingCalls(
      activeVoiceCallConversationId,
    );
    if (!voiceCallObj) return;

    const { voiceConversation } = voiceCallObj;
    const { id: voiceConversationId, answeredByMachineAt } = voiceConversation;
    const isAnsweredByMachine = !!answeredByMachineAt;

    if (isAnsweredByMachine) {
      await endVoiceCall({ conversationId: activeVoiceCallConversationId });
      return;
    }

    await switchAwayFromVoiceCall({
      variables: { input: { voiceConversationId } },
    });
  }, [
    activeVoiceCallConversationId,
    isAgentMonitoringAnyCall,
    endVoiceCall,
    getCallObjFromOngoingCalls,
    switchAwayFromVoiceCall,
  ]);

  /* Responsible for answering an incoming call */
  const answerIncomingCall = useCallback(
    async (conversationId) => {
      if (!voiceApp) return;

      if (isVideoCallInUse) {
        setErrorMessageSnackbar(
          "Can not answer voice call while in video call",
        );
        return;
      }

      if (isAnyVoiceCallStarting) {
        setErrorMessageSnackbar("Starting / switching voice call in progress");
        return;
      }

      if (isAgentMonitoringAnyCall) {
        await endVoiceCall({ conversationId: activeVoiceCallConversationId });

        /* Set the id of the conversation we are starting without switching */
        setConversationStartingVoiceCall(conversationId);
      } else {
        /* Set the id of the conversation we are starting/switching to */
        setConversationIdStartAndSwitchVoiceCall(conversationId);
      }

      try {
        /* Move away from current active call */
        await switchAwayFromActiveCall();
        const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
        const vonageClientConversationId =
          voiceCallUtils.getClientAgentIpCallId(voiceCallObj);
        const nexmoCall = getNexmoCall(vonageClientConversationId);

        if (nexmoCall) {
          await nexmoCall.answer();
        } else {
          /* Reset everything if we failed to find the nexmoCall in the client */
          setConversationIdStartAndSwitchVoiceCall();
          dispatch(voiceCallActions.removeOngoingVoiceCall({ conversationId }));
          console.error(
            "No nexmo call was found in the client while attempting to answer call",
          );
        }
      } catch (error) {
        console.error("Unable to answer incoming call", error);

        setConversationIdStartAndSwitchVoiceCall();

        checkBrowserMicrophonePermission({
          onPermissionAllowed: () => {
            setErrorMessageSnackbar("Failed to answer incoming call");
          },
          onPermissionDenied: () => {
            setErrorMessageSnackbar(
              "Please allow microphone permission to start the call",
            );
          },
        });
      }
    },
    [
      voiceApp,
      activeVoiceCallConversationId,
      isAgentMonitoringAnyCall,
      isAnyVoiceCallStarting,
      isVideoCallInUse,
      dispatch,
      endVoiceCall,
      getCallObjFromOngoingCalls,
      getNexmoCall,
      setConversationIdStartAndSwitchVoiceCall,
      setConversationStartingVoiceCall,
      setErrorMessageSnackbar,
      switchAwayFromActiveCall,
    ],
  );

  const switchActiveVoiceCall = useCallback(
    async (conversationId) => {
      if (!conversationId) return;
      if (activeVoiceCallConversationId === conversationId) return;
      if (conversationIdSwitchingCallTo === conversationId) return;
      if (isAnyVoiceCallStarting) {
        setErrorMessageSnackbar("Starting / switching voice call in progress");
        return;
      }

      if (isVideoCallInUse) {
        setErrorMessageSnackbar(
          "Can not switch active voice call while in video call",
        );
        return;
      }

      /* Set the id of the conversation we are switching to */
      setConversationIdSwitchingVoiceCallTo(conversationId);

      try {
        /* Move away from current active call */
        await switchAwayFromActiveCall();
        const voiceCallObj = getCallObjFromOngoingCalls(conversationId);
        if (!voiceCallObj) {
          setConversationIdSwitchingVoiceCallTo();
          return;
        }

        const { voiceConversation } = voiceCallObj;
        const { id: voiceConversationId } = voiceConversation;

        await switchToVoiceCall({
          variables: { input: { voiceConversationId } },
        });

        setActiveVoiceCallConversationId(conversationId);
        setConversationIdSwitchingVoiceCallTo();
      } catch (error) {
        setConversationIdSwitchingVoiceCallTo();
        setErrorMessageSnackbar("Failed to switch call");
      }
    },
    [
      activeVoiceCallConversationId,
      conversationIdSwitchingCallTo,
      isAnyVoiceCallStarting,
      isVideoCallInUse,
      getCallObjFromOngoingCalls,
      setActiveVoiceCallConversationId,
      setConversationIdSwitchingVoiceCallTo,
      setErrorMessageSnackbar,
      switchAwayFromActiveCall,
      switchToVoiceCall,
    ],
  );

  const voiceCallProviderContextValue = useMemo(() => {
    return {
      currentConversationId,
      isMuteActionLoading,
      isHoldActionLoading,
      isAgentInCurrentConvoVoiceCallInCurrentTab,
      isAgentInCurrentConvoVoiceCallInAnotherTab,
      isFocusedOnConversationWithOngoingVoiceCall,
      activeVoiceCallConversationId,
      conversationIdStartingVoiceCall,
      isVoiceClientReady,
      isAgentMonitoringAnyCall,
      isMultiCall,
      isVideoCallInUse,
      isStartingVoiceCall,
      isSwitchingActiveVoiceCall,
      isAnyVoiceCallStarting,
      isReconnectingVoiceCallMonitor,
      onStartVoiceCall: startVoiceCall,
      onEndVoiceCall: endVoiceCall,
      onToggleMicrophone: toggleMicrophone,
      onToggleHoldCall: toggleHoldCall,
      onAnswerIncomingCall: answerIncomingCall,
      onSwitchActiveVoiceCall: switchActiveVoiceCall,
    };
  }, [
    currentConversationId,
    isMuteActionLoading,
    isHoldActionLoading,
    isAgentInCurrentConvoVoiceCallInCurrentTab,
    isAgentInCurrentConvoVoiceCallInAnotherTab,
    isFocusedOnConversationWithOngoingVoiceCall,
    activeVoiceCallConversationId,
    conversationIdStartingVoiceCall,
    isVoiceClientReady,
    isAgentMonitoringAnyCall,
    isMultiCall,
    isVideoCallInUse,
    isStartingVoiceCall,
    isSwitchingActiveVoiceCall,
    isAnyVoiceCallStarting,
    isReconnectingVoiceCallMonitor,
    startVoiceCall,
    endVoiceCall,
    toggleMicrophone,
    toggleHoldCall,
    answerIncomingCall,
    switchActiveVoiceCall,
  ]);

  const voiceCallButtonContextValue = useMemo(() => {
    return {
      autoSelectOutboundCallGroups,
      voiceConfiguration,
      voiceProviderAccounts,
      isVoiceCallAvailable,
      isAgentInAnyCall,
      onStartVoiceCall: startVoiceCall,
    };
  }, [
    autoSelectOutboundCallGroups,
    voiceConfiguration,
    voiceProviderAccounts,
    isVoiceCallAvailable,
    isAgentInAnyCall,
    startVoiceCall,
  ]);

  const voiceCallMeetingRoomContextValue = useMemo(() => {
    return {
      currentConversationVoiceCall,
      cancelVoiceCallInviteState,
      cancelVoiceConversationInviteLoading,
      onCancelVoiceCallInvite: cancelVoiceCallInvite,
    };
  }, [
    currentConversationVoiceCall,
    cancelVoiceCallInviteState,
    cancelVoiceConversationInviteLoading,
    cancelVoiceCallInvite,
  ]);

  const conversationEventSubscriptionContextValue = useMemo(() => {
    return {
      onToggleNexmoCallAudioInput: toggleNexmoCallAudioInput,
    };
  }, [toggleNexmoCallAudioInput]);

  const floatingCallWidgetContainerContextValue = useMemo(() => {
    return {
      ongoingVoiceCalls,
      onHandleConversationSwitch: handleConversationSwitch,
      onSetConversationIdStartAndSwitchVoiceCall:
        setConversationIdStartAndSwitchVoiceCall,
    };
  }, [
    ongoingVoiceCalls,
    handleConversationSwitch,
    setConversationIdStartAndSwitchVoiceCall,
  ]);

  const joinVoiceCallButtonContextValue = useMemo(() => {
    return {
      currentConversationLastVoiceConversation,
      hasOngoingNonMonitoredVoiceCall,
      isAgentInAnyCall,
      isAnyVoiceCallStarting,
      isVoiceCallAvailable,
      isVideoCallInUse,
      onStartVoiceCall: startVoiceCall,
    };
  }, [
    currentConversationLastVoiceConversation,
    hasOngoingNonMonitoredVoiceCall,
    isAgentInAnyCall,
    isAnyVoiceCallStarting,
    isVoiceCallAvailable,
    isVideoCallInUse,
    startVoiceCall,
  ]);

  const voiceCallInvitationModalContextValue = useMemo(() => {
    return {
      activeConversationVoiceCallId: activeConversationVoiceCall?.id,
      isVoiceCallInvitationModalShown,
      isInviteToVoiceConversationLoading,
      onOpenVoiceCallInvitationModal: handleOpenVoiceCallInvitationModal,
      onCloseVoiceCallInvitationModal: handleCloseVoiceCallInvitationModal,
      onInviteParticipantToVoiceCall: inviteParticipantToVoiceCall,
    };
  }, [
    activeConversationVoiceCall?.id,
    isVoiceCallInvitationModalShown,
    isInviteToVoiceConversationLoading,
    handleOpenVoiceCallInvitationModal,
    handleCloseVoiceCallInvitationModal,
    inviteParticipantToVoiceCall,
  ]);

  const leaveVoiceCallModalContextValue = useMemo(() => {
    return {
      activeConversationVoiceCall,
      activeVoiceCallConversationId,
      isLeaveVoiceCallModalShown,
      isAgentTransferLoading,
      onCloseLeaveVoiceCallModal: handleCloseLeaveVoiceCallModal,
    };
  }, [
    activeConversationVoiceCall,
    activeVoiceCallConversationId,
    isLeaveVoiceCallModalShown,
    isAgentTransferLoading,
    handleCloseLeaveVoiceCallModal,
  ]);

  return (
    <VoiceCallProviderContext.Provider value={voiceCallProviderContextValue}>
      <VoiceCallButtonContext.Provider value={voiceCallButtonContextValue}>
        <VoiceCallMeetingRoomContext.Provider
          value={voiceCallMeetingRoomContextValue}
        >
          <FloatingCallWidgetContainerContext.Provider
            value={floatingCallWidgetContainerContextValue}
          >
            <ConversationEventSubscriptionContext.Provider
              value={conversationEventSubscriptionContextValue}
            >
              <JoinVoiceCallButtonContext.Provider
                value={joinVoiceCallButtonContextValue}
              >
                <VoiceCallInvitationModalContext.Provider
                  value={voiceCallInvitationModalContextValue}
                >
                  <LeaveVoiceCallModalContext.Provider
                    value={leaveVoiceCallModalContextValue}
                  >
                    {children}
                  </LeaveVoiceCallModalContext.Provider>
                </VoiceCallInvitationModalContext.Provider>
              </JoinVoiceCallButtonContext.Provider>
            </ConversationEventSubscriptionContext.Provider>
          </FloatingCallWidgetContainerContext.Provider>
        </VoiceCallMeetingRoomContext.Provider>
      </VoiceCallButtonContext.Provider>
    </VoiceCallProviderContext.Provider>
  );
};

export const useVoiceCallContext = () => useContext(VoiceCallProviderContext);

export const useVoiceCallButtonContext = () =>
  useContext(VoiceCallButtonContext);

export const useVoiceCallMeetingRoomContext = () =>
  useContext(VoiceCallMeetingRoomContext);

export const useFloatingCallWidgetContainerContext = () =>
  useContext(FloatingCallWidgetContainerContext);

export const useConversationEventSubscriptionContext = () =>
  useContext(ConversationEventSubscriptionContext);

export const useJoinVoiceCallButtonContext = () =>
  useContext(JoinVoiceCallButtonContext);

export const useVoiceCallInvitationModalContext = () =>
  useContext(VoiceCallInvitationModalContext);

export const useLeaveVoiceCallModalContext = () =>
  useContext(LeaveVoiceCallModalContext);

export default VoiceCallProvider;
