import * as apolloActions from "@/actions/apolloActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";
import { wsConnectionStatusEnum } from "@/enums/networkEnum";

const defaultState = Object.freeze({
  /* Indicates if instance of apollo client was made with an access token */
  isAuthClientReady: false,
  wsConnectionStatus: wsConnectionStatusEnum.initial,
});

const apolloReducer = (state = defaultState, action) => {
  switch (action.type) {
    case apolloActions.SET_AUTH_CLIENT_READY: {
      return Object.freeze({
        ...state,
        isAuthClientReady: action.isAuthClientReady,
      });
    }

    case apolloActions.SET_WS_CONNECTION_STATUS: {
      return Object.freeze({
        ...state,
        wsConnectionStatus: action.wsConnectionStatus,
      });
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default apolloReducer;
