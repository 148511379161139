import * as errorPageActions from "@/actions/errorPageActions";
import { LOG_OUT_SUCCESS } from "@/actions/userActions";

const defaultState = Object.freeze({
  isShown: false,
  title: null,
  statusCode: null,
  message: null,
});

const errorPageReducer = (state = defaultState, action) => {
  switch (action.type) {
    case errorPageActions.SET_ERROR_PAGE_CONTENT: {
      const { title, statusCode, message } = action;
      const isShown = Boolean(title || statusCode || message);

      return Object.freeze({
        ...state,
        isShown,
        title: title,
        statusCode: statusCode,
        message: message,
      });
    }

    case errorPageActions.CLEAR_ERROR_PAGE: {
      return defaultState;
    }

    case LOG_OUT_SUCCESS: {
      return defaultState;
    }

    default:
      return state;
  }
};

export default errorPageReducer;
