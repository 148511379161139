import { gql } from "@apollo/client";

import {
  allAverageVoiceCallTimeReportDefinition,
  allTotalVoiceCallCountReportDefinition,
  allTotalVoiceCallTimeReportDefinition,
  voiceReportMetricsPerPeriodVarDefinition,
} from "@/definitions/reporting/voiceReportingDefinitions";

/* Total call count queries */

export const GET_TEAM_ALL_TOTAL_VOICE_CALL_COUNT_REPORT = gql`
  query GetTeamAllTotalVoiceCallCountReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${allTotalVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_ALL_TOTAL_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAllTotalVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${allTotalVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_ALL_TOTAL_VOICE_CALL_COUNT_REPORT = gql`
  query GetAgentAllTotalVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${allTotalVoiceCallCountReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_ALL_TOTAL_VOICE_CALL_COUNT_REPORT = gql`
  query GetGroupAgentAllTotalVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${allTotalVoiceCallCountReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_ALL_TOTAL_VOICE_CALL_COUNT_REPORT = gql`
  query GetDIDAllTotalVoiceCallCountReport(
    ${voiceReportMetricsPerPeriodVarDefinition}
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${allTotalVoiceCallCountReportDefinition}
      }
    }
  }
`;

/* Total call time queries */

export const GET_TEAM_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamAllTotalVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${allTotalVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_ALL_TOTAL_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDAllTotalVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${allTotalVoiceCallTimeReportDefinition}
      }
    }
  }
`;

/* Average call time queries */

export const GET_TEAM_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetTeamAllAverageVoiceCallTimeReport(${voiceReportMetricsPerPeriodVarDefinition}) {
    voiceReporting {
      team {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      groups (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_AGENT_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetAgentAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      agents (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;

export const GET_GROUP_AGENT_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetGroupAgentAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $groupId: ID
    $agentId: ID
  ) {
    voiceReporting {
      groups (id: $groupId) {
        agents (id: $agentId) {
          ${allAverageVoiceCallTimeReportDefinition}
        }
      }
    }
  }
`;

export const GET_DID_ALL_AVERAGE_VOICE_CALL_TIME_REPORT = gql`
  query GetDIDAllAverageVoiceCallTimeReport(
    ${voiceReportMetricsPerPeriodVarDefinition},
    $id: ID
  ) {
    voiceReporting {
      voiceProviderAccounts (id: $id) {
        ${allAverageVoiceCallTimeReportDefinition}
      }
    }
  }
`;
