import { SubscriptionClient } from "subscriptions-transport-ws";

import { getServerSettings } from "@/utils/serverUtils";

export const createSubscriptionClient = ({
  token,
  connectCallback,
  disconnectCallback,
  reconnectingCallback,
  reconnectedCallback,
}) => {
  if (!token) return null;

  let ack = false;
  const { websocketUrl } = getServerSettings();

  /* Create subscription client */
  const subscriptionClient = new SubscriptionClient(websocketUrl, {
    reconnect: true,
    connectionParams: { authToken: `Bearer ${token}` },
    timeout: 75000,
    minTimeout: 10000,
    connectionCallback: () => (ack = true),
  });

  subscriptionClient.use([
    {
      applyMiddleware(options, next) {
        /* Wait for ack to be true before attempting any operation */
        if (!ack) throw new Error("not ready");
        next();
      },
    },
  ]);

  const handleClientOnMessage = ({ data }) => {
    const { type } = JSON.parse(data) || {};

    /* Let backend know user is still online */
    if (type === "ka") return subscriptionClient.sendMessage(undefined, "ka");

    /* Process message as normal */
    subscriptionClient.processReceivedData(data);
  };

  subscriptionClient.onConnected(() => {
    // console.log("WS Connection complete 🚀️");
    ack = true;
    connectCallback();
  });

  subscriptionClient.onDisconnected(() => {
    // console.log("Disconnected WS 👻️");
    ack = false;
    disconnectCallback();
  });

  subscriptionClient.onReconnecting(() => {
    reconnectingCallback();
  });

  subscriptionClient.onReconnected(() => {
    reconnectedCallback();
  });

  subscriptionClient.client.onmessage = handleClientOnMessage;

  return subscriptionClient;
};
