import { all, put, takeLatest } from "redux-saga/effects";

import * as userActions from "@/actions/userActions";
import { unregisterServiceWorker } from "@/utils/pushNotificationUtils";

function* logOut() {
  yield unregisterServiceWorker();
  localStorage.removeItem("token");
  yield put(userActions.logOutSuccess());
}

function* watchLogOut() {
  yield takeLatest(userActions.LOG_OUT_REQUEST, logOut);
}

export default function* userSaga() {
  yield all([watchLogOut()]);
}
