import { useApolloClient, useSubscription } from "@apollo/client";
import React, { useRef } from "react";
import { useSelector } from "react-redux";

import { AGENT_ONLINE_DATA_FRAGMENT } from "@/definitions/agentOnlineDataDefinition";
import { AGENT_INBOX_VIEW_PANE_FRAGMENT } from "@/definitions/allowedInboxView/inboxViewPaneDefinition";
import { conversationStatusInEnum } from "@/enums/conversation";
import { dataObjectTypenameEnum } from "@/enums/typename";
import * as inboxPageQueries from "@/queries/inboxPageQueries";
import * as userSelectors from "@/selectors/userSelectors";
import * as graphqlUtils from "@/utils/graphqlUtils";
import ConversationEventSubscription from "./ConversationEventSubscription";
import NotificationSubscription from "./NotificationSubscription";

const InboxPageSubscriptions = () => {
  const audioRef = useRef();
  const client = useApolloClient();

  const accessToken = useSelector(userSelectors.accessToken);

  useSubscription(
    inboxPageQueries.CONVERSATION_SPECIAL_ALLOWED_INBOX_VIEWS_COUNT_SUBSCRIPTION,
    {
      skip: !accessToken,
      onSubscriptionData: ({ subscriptionData }) => {
        const {
          data: {
            onUpdateAllowedInboxViewConversationCount: {
              allowedInboxViewSpecialsCount,
            } = {},
          } = {},
        } = subscriptionData;

        const key = graphqlUtils.getRootQueryKey({
          queryName: "allowedInboxViews",
          keyArgArray: [
            { keyArg: "statusIn", value: conversationStatusInEnum.active },
          ],
          isDefaultQueryCacheKeyShape: true,
        });

        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            [key]: (existing) => {
              return {
                __typename: existing.__typename,
                specialsCount: allowedInboxViewSpecialsCount,
              };
            },
          },
        });
      },
    },
  );

  /* Subscription for media transcription object when received incoming audio message */
  useSubscription(inboxPageQueries.MEDIA_TRANSCRIPTION_SUBSCRIPTION, {
    skip: !accessToken,
  });

  /* Subscription for conversationMessages status list updates */
  useSubscription(
    inboxPageQueries.CONVERSATION_MESSAGE_STATUS_LIST_SUBSCRIPTION,
    {
      skip: !accessToken,
    },
  );

  useSubscription(inboxPageQueries.AGENT_CURRENT_STATUS_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData;
      if (!data) return;

      const { agentId, agentCurrentStatus } = data.onAgentCurrentStatus;

      const cachedAgentData = client.readFragment({
        id: `${dataObjectTypenameEnum.agentObject}:${agentId}`,
        fragment: AGENT_INBOX_VIEW_PANE_FRAGMENT,
      });

      const newAgentData = {
        ...cachedAgentData,
        agentCurrentStatus,
      };

      client.writeFragment({
        id: `${dataObjectTypenameEnum.agentObject}:${agentId}`,
        fragment: AGENT_INBOX_VIEW_PANE_FRAGMENT,
        data: newAgentData,
      });
    },
  });

  useSubscription(inboxPageQueries.AGENT_ONLINE_DATA_SUBSCRIPTION, {
    onSubscriptionData: ({ subscriptionData }) => {
      const { data } = subscriptionData;
      if (!data) return;

      const { agentOnlineData } = data.onAgentOnlineData;

      const id = client.cache.identify(agentOnlineData);

      client.writeFragment({
        id,
        fragment: AGENT_ONLINE_DATA_FRAGMENT,
        data: agentOnlineData,
      });
    },
  });

  const playNotificationSound = async () => {
    try {
      if (audioRef.current) await audioRef.current.play();
    } catch (error) {
      return;
    }
  };

  return (
    <>
      <NotificationSubscription
        accessToken={accessToken}
        onPlayNotificationSound={playNotificationSound}
      />
      <ConversationEventSubscription
        accessToken={accessToken}
        onPlayNotificationSound={playNotificationSound}
      />
      <audio ref={audioRef}>
        <source src="/sounds/chat_alert.wav" type="audio/wav" />
        <source src="/sounds/chat_alert.mp3" type="audio/mpeg" />
      </audio>
    </>
  );
};

export default InboxPageSubscriptions;
