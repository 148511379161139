import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import React, { useRef, useState } from "react";

import TooltipV2 from "@/components/TooltipV2";

const useStyles = makeStyles(() => ({
  root: {
    whiteSpace: "normal",
    overflow: "hidden",
    wordBreak: "break-word",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
  },
}));

/* Preview specified amount of lines before ellipsis. */
const TextPreview = ({
  children,
  lineClamp = 1,
  TooltipV2Props = {},
  ContainerProps = {},
}) => {
  const classes = useStyles();
  const containerDivRef = useRef();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleOpen = () => {
    const element = containerDivRef.current;

    const isOverflowText =
      element?.scrollHeight > element?.clientHeight ||
      element?.scrollWidth > element?.clientWidth;

    setIsTooltipOpen(isOverflowText);
  };

  const handleClose = () => {
    setIsTooltipOpen(false);
  };

  const { BaseTooltipProps = {}, ...otherTooltipV2Props } = TooltipV2Props;
  const { className, style = {}, ...otherContainerProps } = ContainerProps;

  return (
    <TooltipV2
      isWhiteVersion
      BaseTooltipProps={{
        open: isTooltipOpen,
        onOpen: handleOpen,
        onClose: handleClose,
        ...BaseTooltipProps,
      }}
      {...otherTooltipV2Props}
    >
      <div
        ref={containerDivRef}
        className={clsx(classes.root, className)}
        style={{ WebkitLineClamp: lineClamp, ...style }}
        {...otherContainerProps}
      >
        {children}
      </div>
    </TooltipV2>
  );
};

export default TextPreview;
