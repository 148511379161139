import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { ApolloLink } from "apollo-link";
import { RetryLink } from "apollo-link-retry";
import SerializingLink from "apollo-link-serialize";
import { isEmpty, isNil, uniqBy } from "lodash";

import { dataObjectTypenameEnum } from "@/enums/typename";
import * as reportingUtils from "@/utils/reportingUtils";
import { getServerSettings } from "@/utils/serverUtils";

const checkIfAuthError = ({ error = {} }) => {
  const { graphQLErrors = [], networkError = {} } = error;
  const isAuthGraphQLError = graphQLErrors.find(
    ({ code }) =>
      code === "MISSING_AUTHORIZATION_ERROR" || code === "INVALID_TOKEN_ERROR",
  );

  const isAuthNetworkError = networkError.statusCode === 401;

  return isAuthGraphQLError || isAuthNetworkError;
};

const createApolloClient = ({ token, subscriptionClient, onLogOut }) => {
  const { graphQLUrl } = getServerSettings();

  const httpLink = new HttpLink({
    uri: graphQLUrl,
    headers: { authorization: token ? `Bearer ${token}` : undefined },
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 1000,
      max: 60000,
      jitter: true,
    },
    attempts: (count, operation, e) => {
      const definition = getMainDefinition(operation.query);
      const isSubscription =
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription";

      /* If unauthorized do not retry */
      if (e && e.response && e.response.status === 401) {
        return false;
      }

      /* Retry connection if websocket connection failed */
      if (isSubscription) {
        return count < 5;
      }

      return false;
    },
  });

  /* Responsible for forcing a logout if there is a problem with authorization */
  const resetToken = onError((error) => {
    const isAuthError = checkIfAuthError({ error });

    if (isAuthError) onLogOut();
  });

  const wsLink = subscriptionClient && new WebSocketLink(subscriptionClient);
  const requestLink = subscriptionClient
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        resetToken.concat(httpLink),
      )
    : resetToken.concat(httpLink);

  const serializingLink = new SerializingLink();

  const link = ApolloLink.from([serializingLink, retryLink, requestLink]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        RolePermissionCategoryObject: {
          keyFields: ["id", "roleId"],
        },
        RolePermissionObject: {
          keyFields: ["id", "roleId"],
        },
        RoleMutationPayload: {
          keyFields: ["id"],
        },
        WidgetObject: {
          keyFields: ["id", "kind"],
        },

        MediaObject: {
          keyFields: ["uuid"],
        },

        /* Ensure object is merged correctly for singleton object without ID field */
        FeaturePlanObject: {
          keyFields: [],
        },

        Query: {
          fields: {
            conversations: {
              keyArgs: [
                "id",
                "statusIn",
                "contactId",
                "isPriority",
                "allowedInboxViewInput",
              ],
              merge: (existing = {}, incoming, { args, variables }) => {
                const { results: existingResults = [] } = existing;
                const { offset } = args;
                const { shouldUseIncoming, shouldTryUseExistingConversations } =
                  variables;

                if (shouldUseIncoming) return incoming;

                if (shouldTryUseExistingConversations) {
                  if (existingResults.length === 0) return incoming;
                  return existing;
                }

                if (!offset) return incoming;

                /* Needed to handle edge cases where offset based pagination will return duplicate data */
                const mergedResults = uniqBy(
                  [...existingResults, ...incoming.results],
                  (item) => item.__ref,
                );

                return {
                  results: mergedResults,
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.conversationObjectPaginatedListObject,
                };
              },
              read: (existing, { args, variables, readField }) => {
                const { id } = args;
                const { conversationId } = variables;
                const objectId = id || conversationId;

                if (objectId && existing?.results) {
                  const result = existing.results.find(
                    (conversation) =>
                      readField("id", conversation) === objectId,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.conversationObjectPaginatedListObject,
                  };
                }

                return existing;
              },
            },

            notifications: {
              keyArgs: ["isUnread"],
              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;

                const { results: existingResults = [] } = existing;

                return {
                  results: [...existingResults, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.notificationObjectListObject,
                };
              },
            },

            messagingProviderContacts: {
              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.messagingProviderContactObjectListObject,
                };
              },
              read: (existing, { args, readField }) => {
                if (!args) return existing;

                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (messagingProviderContact) =>
                      readField("id", messagingProviderContact) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.messagingProviderContactObjectListObject,
                  };
                }

                return existing;
              },
            },

            contacts: {
              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename: dataObjectTypenameEnum.contactObject,
                };
              },

              read: (existing, { args = {}, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (contact) => readField("id", contact) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename: dataObjectTypenameEnum.contactObject,
                  };
                }

                return existing;
              },
            },

            tags: {
              merge: (existing = {}, incoming, { args, variables }) => {
                const { offset } = args;
                const { shouldUseIncoming } = variables;

                if (shouldUseIncoming) return incoming;
                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename: dataObjectTypenameEnum.tagObjectListObject,
                };
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (tag) => readField("id", tag) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename: dataObjectTypenameEnum.tagObjectListObject,
                  };
                }

                return existing;
              },
            },

            entities: {
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && !isEmpty(existing)) {
                  return existing.find(
                    (entity) => readField("id", entity) === id,
                  );
                }

                return existing;
              },
            },

            instances: {
              merge: (existing = {}, incoming, { args, variables }) => {
                const { offset } = args;
                const { shouldUseIncoming } = variables;

                if (shouldUseIncoming) return incoming;
                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename: dataObjectTypenameEnum.instanceObjectListObject,
                };
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (instance) => readField("id", instance) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename: dataObjectTypenameEnum.instanceObjectListObject,
                  };
                }

                return existing;
              },
            },

            instanceLists: {
              keyArgs: ["id", "entity"],
              merge: (existing = [], incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                return [...existing, ...incoming];
              },

              read: (existing) => {
                return existing;
              },
            },

            crmCsvImports: {
              merge: (existing = {}, incoming, { args, variables }) => {
                const { offset } = args;
                const { shouldUseIncoming } = variables;

                if (shouldUseIncoming) return incoming;
                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.crmCsvImportObjectListObject,
                };
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (crmCsvImport) => readField("id", crmCsvImport) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.crmCsvImportObjectListObject,
                  };
                }

                return existing;
              },
            },

            agents: {
              merge: (existing = {}, incoming, { args, variables }) => {
                const { offset } = args;
                const { shouldUseIncoming } = variables;

                if (shouldUseIncoming) return incoming;
                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming?.totalCount,
                  __typename: dataObjectTypenameEnum.agentObjectListObject,
                };
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (agent) => readField("id", agent) === id,
                  );
                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename: dataObjectTypenameEnum.agentObjectListObject,
                  };
                }

                return existing;
              },
            },

            voiceReporting: {
              merge: (existing = {}, incoming, { variables }) =>
                reportingUtils.mergeReportingCache({
                  existing,
                  incoming,
                  variables,
                }),
              read: (existing) => {
                return existing;
              },
            },

            reporting: {
              merge: (existing = {}, incoming, { variables }) =>
                reportingUtils.mergeReportingCache({
                  existing,
                  incoming,
                  variables,
                }),
              read: (existing) => {
                return existing;
              },
            },

            rules: {
              keyArgs: ["id", "triggerEventCategory", "triggerEventType"],
              merge: (existing = [], incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                return [...existing, ...incoming];
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing) {
                  return existing.find((rule) => readField("id", rule) === id);
                }

                return existing;
              },
            },

            greetings: {
              keyArgs: ["id"],
              merge: (existing = [], incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                return [...existing, ...incoming];
              },
              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing) {
                  return existing.find(
                    (greeting) => readField("id", greeting) === id,
                  );
                }

                return existing;
              },
            },

            holidaySchedules: {
              merge: (_, incoming) => {
                return incoming;
              },
              read: (existing) => {
                return existing;
              },
            },

            conversationTags: {
              merge: false,
              read: (existing) => existing,
            },

            contactTags: {
              merge: false,
              read: (existing) => existing,
            },

            messageBlastSchedules: {
              merge: (_, incoming) => {
                return incoming;
              },

              read: (existing, { args, readField }) => {
                const { id } = args;

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (messageBlastSchedule) =>
                      readField("id", messageBlastSchedule) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.messageBlastScheduleObject,
                  };
                }

                return existing;
              },
            },

            whatsappHsmTemplates: {
              merge: (_, incoming) => {
                return incoming;
              },

              read: (existing, { args, readField }) => {
                const { id } = args || {};

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (whatsappHsmTemplate) =>
                      readField("id", whatsappHsmTemplate) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.whatsappHSMTemplateObjectListObject,
                  };
                }

                return existing;
              },
            },

            paymentSessions: {
              keyArgs: ["contactId"],
              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args || {};

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  results: [...existing.results, ...incoming.results],
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.paymentSessionObjectListObject,
                };
              },

              read: (existing, { args, readField }) => {
                const { id } = args || {};

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (paymentSession) => readField("id", paymentSession) === id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.paymentSessionObjectListObject,
                  };
                }

                return existing;
              },
            },

            externalCommunicationConfiguration: {
              merge: (_, incoming) => {
                return incoming;
              },

              read: (existing, { args, readField }) => {
                const { id } = args || {};

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (externalCommunicationConfiguration) =>
                      readField("id", externalCommunicationConfiguration) ===
                      id,
                  );

                  if (!result) return;

                  return {
                    results: [result],
                    totalCount: 1,
                    __typename:
                      dataObjectTypenameEnum.externalCommunicationConfigurationObjectListObject,
                  };
                }

                return existing;
              },
            },

            transcriptionSegments: {
              keyArgs: ["recordingId"],
              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args || {};

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  ...incoming,
                  results: [...existing.results, ...incoming.results],
                };
              },

              read: (existing) => existing,
            },

            landingPageDocumentVisitLogs: {
              keyArgs: ["id", "contactId"],

              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args || {};

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  ...incoming,
                  results: [...existing.results, ...incoming.results],
                };
              },

              read: (existing, { args, readField }) => {
                const { id } = args || {};

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (landingPageDocumentVisitLog) =>
                      readField("id", landingPageDocumentVisitLog) === id,
                  );

                  if (!result) return;
                  return { ...existing, results: [result], totalCount: 1 };
                }

                return existing;
              },
            },

            landingPageDocumentShortLinks: {
              keyArgs: ["id", "contactId"],

              merge: (existing = {}, incoming, { args }) => {
                const { offset } = args || {};

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                return {
                  ...incoming,
                  results: [...existing.results, ...incoming.results],
                };
              },

              read: (existing, { args, readField }) => {
                const { id } = args || {};

                if (id && existing?.results) {
                  const result = existing.results.find(
                    (landingPageDocumentShortLink) =>
                      readField("id", landingPageDocumentShortLink) === id,
                  );

                  if (!result) return;
                  return { ...existing, results: [result], totalCount: 1 };
                }

                return existing;
              },
            },
          },
        },

        ConversationAllowedInboxViewObject: {
          specialCounts: {
            merge: false,
          },

          /* Disable keyArgs for these fields for it has no significance */
          fields: {
            conversationTags: {
              keyArgs: false,
            },
            contactTags: {
              keyArgs: false,
            },
            agents: {
              keyArgs: false,
            },
            groups: {
              keyArgs: false,
            },
          },
        },

        ConversationObject: {
          fields: {
            conversationTags: {
              merge: false,
              read: (existing) => existing,
            },

            assignee: {
              merge: (existing = {}, incoming) => {
                return { current: incoming, previous: existing.current };
              },

              read: (existing, { variables }) => {
                const { getPreviousAssignee } = variables;

                if (existing) {
                  if (getPreviousAssignee) return existing.previous || null;
                  return existing.current;
                }

                return existing;
              },
            },

            events: {
              keyArgs: ["createdGte", "createdLt"],
              merge: (existing = {}, incoming, { variables, args }) => {
                const {
                  targetEventsObject,
                  shouldTryUseExistingConversationEvents,
                  shouldUseZeroPaginationOffsetAsFirstPage = true,
                } = variables;
                const { pointer, offset: paginationOffset } = args;

                const existingTargetObject = existing[targetEventsObject] || {};

                const existingResults = existingTargetObject.results || [];
                const incomingResults = incoming.results;

                const existingOffset = existingTargetObject.offset;
                const incomingOffset = incoming.offset;

                const shouldReturnIncoming =
                  !!pointer ||
                  (shouldUseZeroPaginationOffsetAsFirstPage &&
                    paginationOffset === 0) ||
                  isNil(existingOffset) ||
                  existingResults.length === 0;

                const { newResultsArray, newOffset } = (() => {
                  if (shouldTryUseExistingConversationEvents) {
                    if (existingResults.length === 0) {
                      return {
                        newResultsArray: incomingResults,
                        newOffset: incomingOffset,
                      };
                    }

                    return {
                      newResultsArray: existingResults,
                      newOffset: existingOffset,
                    };
                  }

                  if (shouldReturnIncoming) {
                    return {
                      newResultsArray: incomingResults,
                      newOffset: incomingOffset,
                    };
                  }

                  const shouldInsertAtStart = incomingOffset < existingOffset;

                  if (shouldInsertAtStart) {
                    return {
                      newResultsArray: [...incomingResults, ...existingResults],
                      newOffset: Math.min(existingOffset, incomingOffset),
                    };
                  }

                  return {
                    newResultsArray: [...existingResults, ...incomingResults],
                    newOffset: Math.min(existingOffset, incomingOffset),
                  };
                })();

                return {
                  ...existing,
                  [targetEventsObject]: {
                    ...existingTargetObject,
                    ...incoming,
                    results: newResultsArray,
                    offset: newOffset,
                  },
                };
              },

              read: (existing = {}, { variables }) => {
                const {
                  targetEventsObject,
                  shouldReturnNullWhenEventsNotFoundInRead,
                } = variables;

                const existingTargetObject = existing[targetEventsObject];

                const shouldReturnNull =
                  !existingTargetObject &&
                  shouldReturnNullWhenEventsNotFoundInRead;

                if (shouldReturnNull) return null;
                return existingTargetObject;
              },
            },
          },
        },

        ContactObject: {
          fields: {
            contactTags: {
              merge: false,
              read: (existing) => existing,
            },
            events: {
              merge: (existing, incoming, { args }) => {
                const { offset } = args;

                if (!offset) return incoming;
                if (isEmpty(existing)) return incoming;

                const mergedResults = [
                  ...existing.results,
                  ...incoming.results,
                ];

                return {
                  results: mergedResults,
                  totalCount: incoming.totalCount,
                  __typename:
                    dataObjectTypenameEnum.relatedEventObjectPaginatedListObject,
                };
              },
              read: (existing) => {
                return existing;
              },
            },
          },
        },

        TagObject: {
          fields: {
            allowedContentTypes: {
              merge: false,
            },
          },
        },

        InstanceObject: {
          fields: {
            instanceLists: {
              merge: false,
            },
          },
        },

        AgentObject: {
          fields: {
            agentCurrentStatus: {
              merge: false,
            },
          },
        },

        VoiceConversationObject: {
          fields: {
            currentParticipants: {
              merge: (existing, incoming) => incoming,
            },
          },
        },

        MessageBlastScheduleObject: {
          fields: {
            instanceLists: {
              merge: (existing, incoming) => incoming,
            },
            instanceListSnapshots: {
              merge: (existing, incoming) => incoming,
            },
            contactTags: {
              merge: (existing, incoming) => incoming,
            },
          },
        },

        UserObject: {
          keyFields: ["email"],
        },

        WhatsappHSMSupportedLanguageObject: {
          keyFields: ["languageCode"],
        },
      },
    }),
  });
};

export const initializeApollo = ({
  initialState = null,
  token,
  subscriptionClient,
  onLogOut,
}) => {
  const _apolloClient = createApolloClient({
    token,
    subscriptionClient,
    onLogOut,
  });

  /* 
      If your page has Next.js data fetching methods that use Apollo Client, 
      the initial state get hydrated here 
    */
  if (initialState) {
    /* Get existing cache, loaded during client side data fetching */
    const existingCache = _apolloClient.extract();
    /* 
        Restore the cache using the data passed from getStaticProps/getServerSideProps
        combined with the existing cached data 
      */
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  return _apolloClient;
};
